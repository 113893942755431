@import "../../global/variables"
.instance-screen
    flex: 1
    display: flex
    flex-direction: column
    .screen-body
        flex-direction: column
        >.page-header
            display: flex
            flex-direction: row
            margin-bottom: 20px
            .title
                flex: 1
        .MuiDataGrid-row
            cursor: pointer
        .MuiDataGrid-cell
            &:focus
                outline: none
        .empty-img
            align-self: center
            margin-top: 100px
