@import "../../global/variables"
#payment-screen
    .screen-body
        display: block !important // @TODO remover isso quando tira a responsividade
        form
            .MuiPaper-root
                padding: 20px 0px
                height: 93%
            #medalStack
                color: #E6AC18
    .blue-bg
        border-top-left-radius: 15px
        border-top-right-radius: 15px
        background-color: blue
        height: 5px
