@import "../../global/variables"
.batch-upload
    .error-message,.success-message
        margin: 10px 0
        width: 525px
    label
        display: flex
        flex-direction: column
        align-items: center
        justify-content: center
        text-align: center
        width: 500px
        height: 200px
        padding: 30px
        border-radius: 10px
        border: 2px dashed $colors-royal
        background-color: lighten($colors-royal, 30)
        .icon
            color: $colors-blue
        .title
            color: $colors-blue
            font-weight: bold

    .batch-upload-confirm-info
        width: 500px
        height: 200px
        padding: 30px
        border-radius: 10px
        border: 2px dashed $colors-royal
        background-color: lighten($colors-royal, 30)
        .buttons
            padding: 10px
            display: flex
            justify-content: center
            .confirm-button
                margin-right: 10px
