@import "../../global/variables"
.plans-screen
    flex: 1
    display: flex
    flex-direction: column
    .screen-body
        flex-direction: column
        .plans-list
            flex: 1
            display: flex
            align-items: center
            justify-content: center
            >.plan
                display: flex
                flex-direction: column
                align-items: center
                width: 340px
                height: 550px
                padding: 20px
                margin-right: 50px
                .name
                    display: flex
                    align-items: center
                    font-weight: bold
                .subtitle
                    color: $colors-gray
                .price
                    display: flex
                    align-items: center
                    margin-top: 20px
                    margin-bottom: 20px
                    .value
                        font-weight: bold
                        margin-right: 10px
                    .description
                        width: 100px
                        line-height: 1
                .action-button
                    width: 250px
                .features
                    margin-top: 20px

                &.royal
                    .name
                        color: $colors-royal
                &.yellow
                    .name
                        color: $colors-yellow
                    .action-button
                        background-color: $colors-yellow
                &.purple
                    .name
                        color: $colors-purple
                    .action-button
                        background-color: $colors-purple

        // background-image: url('./images/bg.png')
        // background-repeat: no-repeat
        // background-position: bottom right
        // display: block
        // .text-wrapper
        //     width: 600px
        //     margin-bottom: 30px
        //     .plan-name
        //         margin-bottom: 10px
