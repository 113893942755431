@import "../../global/variables"
.screen
    flex: 1
    display: flex
    flex-direction: column
    .screen-header
        padding: 10px 20px
    .screen-body
        flex: 1
        display: flex
        margin-top: 20px
        padding: 20px
