@import "../../global/variables"
.analysis-result-content
    .summary
        padding: 20px
        .summary-item
            display: flex
            align-items: center
            border-right: 1px solid $colors-gray
            padding: 10px
            justify-content: center
            &:last-child
                border-right: none
            .icon
                margin-right: 5px

    .recommendations
        .recommendation-card
            background-color: lighten($colors-alert-dark, 20) !important
            margin: 5px
            >.recommendation-card-header
                padding: 10px
            >.recommendation-card-body
                background-color: lighten($colors-green, 30) !important
                padding: 10px
                min-height: 100px
                .recommendation-card-body-title
                    color: $colors-green-dark
                    display: flex
                    align-items: center
                    .icon
                        margin-right: 10px
    .donut-text
        position: relative
        bottom: 142px

    .overlayer
        position: absolute
        z-index: 1000
        // background-color: #3B8FE480
        color: #676666
    .premium-limitation
        filter: blur(1rem)

#popover
    .MuiPopover-paper
        max-width: 30%
        text-align: justify
